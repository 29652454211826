import { post } from '@/utils/request'

//获取列表
export function getList(data) {
    return post('psi/classify/listClass', data)
}
// 添加分类
export function getAdd(data) {
    return post('psi/classify/add', data)
}
//修改分类
export function getEdit(data) {
    return post('psi/classify/editClass', data)
}
//删除分类
export function getDel(data) {
    return post('psi/classify/delClass', data)
}
//分类下拉
export function getOption(data) {
    return post('psi/classify/downward', data)
}
//项目下拉
export function getMerchant() {
    return post('admin/merchant/section')
}
//状态修改
export function getClassSta(data) {
    return post('psi/classify/classSta', data)
}

