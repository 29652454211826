<!--  -->
<template>
  <div class="">
    <all-log :dialogLog="dialogLog" />
    <!-- 新增与编辑分类 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose()"
      append-to-body
    >
      <div slot="title" v-show="open">新增分类</div>
      <div slot="title" v-show="!open">编辑分类</div>
      <el-form :model="form">
        <el-form-item label="分类名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="分类名称"></el-input>
        </el-form-item>
        <el-form-item label="分类ID" :label-width="formLabelWidth">
          <el-select
            v-model="form.pid"
            style="width: 100%"
            placeholder="请选择上级分类ID"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.pid"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="排序" :label-width="formLabelWidth">
          <el-input v-model="form.order" placeholder="分类排序"></el-input>
        </el-form-item>
        <el-form-item label="关联任务" :label-width="formLabelWidth">
          <el-cascader
            placeholder="关联任务"
            :options="optionsTask"
            :props="optionPropsTask"
            v-model="form.task_id"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="项目" :label-width="formLabelWidth">
          <el-select
            v-model="form.m_id"
            style="width: 100%"
            placeholder="请选择项目"
            clearable
            multiple
          >
            <el-option
              v-for="item in optionsMer"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片" :label-width="formLabelWidth">
          <el-upload
            :headers="token"
            accept=".jpg,.png"
            :on-remove="imgRemove"
            :limit="3"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            :on-success="imgSuccess"
            name="image"
            :file-list="fileList"
          >
            <el-button size="small" type="primary">上传轮播图</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setOpen()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 删除分类 -->
    <el-dialog :visible.sync="dialogFormVisible2" @close="userClose()">
      <div slot="title">删除分类</div>
      <div style="margin-bottom: 10px">{{ userdelinfo.name }}</div>
      <el-cascader
        placeholder="关联任务"
        :options="optionsTask"
        :props="optionPropsTask"
        v-model="delForm.task_id"
        filterable
      ></el-cascader>

      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="setDel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-card>
      <div class="menu-box">
        <h2>商品分类管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="addOpen(true)"
                >添加分类</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
            show-checkbox
            :expand-on-click-node="false"
            row-key="id"
            :tree-props="{ children: 'son', hasChildren: 'hasChildren' }"
          >
            <el-table-column prop="name" label="分类名称">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.name }}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="state" label="分类状态">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.state"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @click.native="changeState(scope.row)"
                />
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="addOpen(false, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- 表格 -->
          <!-- <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column
          prop="name"
          label="分类名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="p_name" label="上级名称"></el-table-column>

        <el-table-column label="分类状态" width="120">
          <template slot-scope="scope">
            <el-switch
              @change="changeState(scope.row)"
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="2"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              icon="el-icon-edit"
              @click="addOpen(false, scope.row)"
            >编辑</el-button>
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="delOpen(scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table> -->
          <!-- 分页 -->
          <!-- <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20, 50]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination> -->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getAdd,
  getEdit,
  getDel,
  getOption,
  getClassSta,
  getMerchant,
} from '@/api/psi/psiClass.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      fileList: [],
      delForm: {
        task_id: [],
      },
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      optionsTask: [],
      optionsMer: [],
      dialogLog: {
        state: false,
      },
      loading: false,
      options: [],
      inputSearch: '',
      formLabelWidth: '100px',
      dialogFormVisible: false,
      dialogFormVisible2: false,
      tableData: [],
      getNav: [],
      //总页数

      getNav: [],
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        // page_code: 1,
        // page_num: 50,
      },
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
        pid: '',
        order: '',
        task_id: [],
        img: [],
        m_id: [],
      },
      open: true,
      tableLoading: false,
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    exceed() {
      this.$message({
        message: '数量超出',
        type: 'warning',
      })
    },
    //导入图片事件
    imgSuccess(response, file, fileList) {
      this.form.img.push(response.data.url)
    },
    //移除图片的事件
    imgRemove(file, fileList) {
      this.form.img = []
      fileList.forEach((item) => {
        if (item.response) {
          this.form.img.push(item.response.data.url)
        } else {
          this.form.img.push(item.url)
        }
      })
      console.log(this.form)
    },

    dialogOpen() {
      this.dialogLog.state = true
    },

    //状态修改
    async changeState(data) {
      let info = await getClassSta({ id: data.id })
      this.judge(info, 0, 1)
      this.catalogue()
    },
    delOpen(info) {
      this.dialogFormVisible2 = true
      this.userdelinfo = info
    },
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      this.judge(info, 0)
    },
    async addOpen(state, row) {
      console.log(row)
      this.open = state
      if (!state) {
        if (row.img) {
          row.img.forEach((item, index) => {
            let info = { name: row.name + index, url: item }
            console.log(info)
            this.fileList.push(info)
          })
        }
        this.userdelinfo = row
        console.log(row.m_id)
        if (row.m_id) {
          let arr = row.m_id.split(',')
          console.log(arr)
          for (let i = 0; i < arr.length; i++) {
            arr[i] = Number(arr[i])
            console.log(arr[i])
          }
          console.log(arr)
          this.form.m_id = arr
        } else {
          this.form.m_id = []
        }

        //console.log(this.form.m_id);

        //console.log(arr);

        this.form.pid = row.pid
        this.form.name = row.name
        this.form.order = row.order
        this.form.img = row.img

        // this.form.m_id.push(Number(row.m_id))
      }
      this.dialogFormVisible = true
    },
    async setOpen() {
      let info = null
      let state = this.open
      if (state) {
        this.form.pid = this.form.pid
        info = await getAdd(this.form)
        console.log('增加')
        //this.catalogue()
      } else {
        let form = this.form
        form.id = this.userdelinfo.id
        info = await getEdit(form)
        console.log('编辑')
        //this.catalogue()
      }
      this.judge(info, true)
    },
    judge(info, change, list) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        if (list == null) {
          this.catalogue()
        }
        if (change) {
          this.dialogFormVisible = false
        } else {
          this.dialogFormVisible2 = false
        }
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
      if (list == null) {
        this.catalogue()
      }
    },
    userClose() {
      this.form = {
        name: '',
        pid: '',
        order: '',
        task_id: [],
        img: [],
        m_id: [],
      }
      this.fileList = []
    },
    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    async catalogue() {
      this.tableLoading = true
      let info = await getList(this.getInfo)
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
      let info2 = await getOption()
      this.options = info2.data.data
      // this.exp.count = info.data.exp.count
      // this.exp.num = info.data.exp.num
      this.tableData = info.data.data
      this.tableLoading = false
      this.merchantList()
    },
    async merchantList() {
      let res = await getMerchant()
      console.log(res)
      this.optionsMer = res.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style></style>
